import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e0563d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconInformation = _resolveComponent("IconInformation")!
  const _component_IconEyeOpened = _resolveComponent("IconEyeOpened")!
  const _component_IconEyeClosed = _resolveComponent("IconEyeClosed")!
  const _component_IconWidescreenOn = _resolveComponent("IconWidescreenOn")!
  const _component_IconWidescreenOff = _resolveComponent("IconWidescreenOff")!
  const _component_IconSoundOn = _resolveComponent("IconSoundOn")!
  const _component_IconSoundOff = _resolveComponent("IconSoundOff")!
  const _component_IconBurger = _resolveComponent("IconBurger")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isDesktop)
      ? (_openBlock(), _createBlock(_component_IconInformation, {
          key: 0,
          title: "Таблица выплат",
          onClick: _ctx.openInfo,
          class: "menu-top__info"
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_IconEyeOpened, {
      class: _normalizeClass(["menu-top__eye", {
        '--disabled': _ctx.controlDisabled || _ctx.isDoublingOn || _ctx.makeTicketDisabled || _ctx.disconnected,
      }]),
      onClick: _ctx.switchScoreboardMode
    }, null, 8, ["onClick", "class"]), [
      [_vShow, _ctx.scoreboardMode === 'digit']
    ]),
    _withDirectives(_createVNode(_component_IconEyeClosed, {
      class: _normalizeClass(["menu-top__eye", {
        '--disabled': _ctx.controlDisabled || _ctx.isDoublingOn || _ctx.makeTicketDisabled || _ctx.disconnected,
      }]),
      onClick: _ctx.switchScoreboardMode
    }, null, 8, ["onClick", "class"]), [
      [_vShow, _ctx.scoreboardMode === 'fruit']
    ]),
    (_ctx.isDesktop)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _withDirectives(_createVNode(_component_IconWidescreenOn, {
            class: _normalizeClass(["menu-top__eye", { '--disabled': _ctx.scoreboardMode === 'fruit' || _ctx.disconnected }]),
            onClick: _ctx.switchWidescreenMode
          }, null, 8, ["class", "onClick"]), [
            [_vShow, _ctx.isWidescreen]
          ]),
          _withDirectives(_createVNode(_component_IconWidescreenOff, {
            class: _normalizeClass(["menu-top__eye", { '--disabled': _ctx.scoreboardMode === 'fruit' || _ctx.disconnected }]),
            onClick: _ctx.switchWidescreenMode
          }, null, 8, ["class", "onClick"]), [
            [_vShow, !_ctx.isWidescreen]
          ])
        ], 64))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_IconSoundOn, {
      class: _normalizeClass(["menu-top__eye", { '--disabled': _ctx.disconnected }]),
      onClick: _ctx.switchSoundMode
    }, null, 8, ["onClick", "class"]), [
      [_vShow, _ctx.isSoundOn]
    ]),
    _withDirectives(_createVNode(_component_IconSoundOff, {
      class: _normalizeClass(["menu-top__eye", { '--disabled': _ctx.disconnected }]),
      onClick: _ctx.switchSoundMode
    }, null, 8, ["onClick", "class"]), [
      [_vShow, !_ctx.isSoundOn]
    ]),
    _createVNode(_component_IconBurger, {
      onClick: _ctx.openMenu,
      class: "menu-top__burger"
    }, null, 8, ["onClick"])
  ]))
}