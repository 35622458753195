
import store from "@/store";
import { defineComponent } from "vue";
import router from "@/router";
import { MevahoMode } from "@/types/user";
import { GameId } from "@/types/user";
import IconEyeClosed from "@/icons/IconEyeClosed.vue";
import IconEyeOpened from "@/icons/IconEyeOpened.vue";
import IconInformation from "@/icons/IconInformation.vue";
import IconWidescreenOn from "@/icons/IconWidescreenOn.vue";
import IconWidescreenOff from "@/icons/IconWidescreenOff.vue";
import IconSoundOn from "@/icons/IconSoundOn.vue";
import IconSoundOff from "@/icons/IconSoundOff.vue";
import IconBurger from "@/icons/IconBurger.vue";
import { AudioUtils } from "@/utils";

export default defineComponent({
  name: "menu-top",
  components: {
    IconBurger,
    IconSoundOff,
    IconSoundOn,
    IconWidescreenOff,
    IconWidescreenOn,
    IconInformation,
    IconEyeOpened,
    IconEyeClosed,
  },
  computed: {
    gameId(): GameId {
      return store.state.currentGameId;
    },
    scoreboardMode(): MevahoMode {
      return store.state.userData.settings.mevaho_mode;
    },
    controlDisabled(): boolean {
      return store.state.ui.controlDisabled;
    },
    isDoublingOn(): boolean {
      return store.state.doubling.isOn;
    },
    isSoundOn(): boolean {
      return store.state.userData.settings.sound === "on";
    },
    isWidescreen(): boolean {
      return store.state.userData?.settings?.widescreen === "on";
    },
    makeTicketDisabled(): boolean {
      return store.state.ui.makeTicketDisabled;
    },
    isDesktop(): boolean {
      return store.state.uiVersion === "desktop";
    },
    disconnected(): boolean {
      return !store.state.connected;
    },
    isFruitMode(): boolean {
      return store.state.userData.settings.mevaho_mode === "fruit";
    },
    isBonusGame(): boolean {
      return store.state.bonus.isOn;
    },
  },
  methods: {
    openMenu() {
      store.commit("setShowMenu", true);
    },
    openInfo() {
      store.dispatch("toggleModal", "payments");
    },
    switchSoundMode() {
      store.dispatch("setSound", this.isSoundOn ? "off" : "on");
      this.soundCondition();
    },
    switchWidescreenMode() {
      store.dispatch("setWidescreen", this.isWidescreen ? "off" : "on");
    },
    switchScoreboardMode() {
      const path = router.currentRoute.value.path;
      store.dispatch("setMevahoMode", this.scoreboardMode === "digit" ? "fruit" : "digit");
      if (path === "/doubling") router.push("/");
      if (
        this.scoreboardMode === "digit" &&
        (this.gameId === "loto-pamir-legends" ||
          this.gameId === "loto-pearl" ||
          this.gameId === "loto-misr")
      ) {
        AudioUtils.stopSound("main-theme-pamir-legends");
        AudioUtils.stopSound("bonus-theme-pamir-legends");
        AudioUtils.stopSound("main-theme-pearl");
        AudioUtils.stopSound("bonus-theme-pearl");
        AudioUtils.stopSound("main-theme-misr");
        AudioUtils.stopSound("bonus-theme-misr");
        AudioUtils.stopSound("main-theme-khoja");
        AudioUtils.stopSound("bonus-theme-khoja");
      } else if (this.scoreboardMode === "fruit" && this.gameId === "loto-pamir-legends") {
        AudioUtils.stopSound("main-theme-pamir-legends");
        AudioUtils.playSound("main-theme-pamir-legends", 0, 0, true);
      } else if (this.scoreboardMode === "fruit" && this.gameId === "loto-pearl") {
        AudioUtils.stopSound("main-theme-pearl");
        AudioUtils.playSound("main-theme-pearl", 0, 0, true);
      } else if (this.scoreboardMode === "fruit" && this.gameId === "loto-misr") {
        AudioUtils.stopSound("main-theme-misr");
        AudioUtils.playSound("main-theme-misr", 0, 0, true);
      } else if (this.scoreboardMode === "fruit" && this.gameId === "loto-khoja") {
        AudioUtils.stopSound("main-theme-khoja");
        AudioUtils.playSound("main-theme-khoja", 0, 0, true);
      }
    },
    soundCondition() {
      console.log("isSoundOn", this.isSoundOn);
      if (!this.isSoundOn) {
        AudioUtils.stopSound("main-theme-pamir-legends");
        AudioUtils.stopSound("bonus-theme-pamir-legends");
        AudioUtils.stopSound("main-theme-pearl");
        AudioUtils.stopSound("bonus-theme-pearl");
        AudioUtils.stopSound("main-theme-misr");
        AudioUtils.stopSound("bonus-theme-misr");
        AudioUtils.stopSound("main-theme-khoja");
        AudioUtils.stopSound("bonus-theme-khoja");
      } else if (
        this.isSoundOn &&
        this.gameId === "loto-pamir-legends" &&
        this.scoreboardMode === "fruit" &&
        !this.isBonusGame
      ) {
        AudioUtils.stopSound("main-theme-pamir-legends");
        AudioUtils.playSound("main-theme-pamir-legends", 0, 0, true);
      } else if (
        this.isSoundOn &&
        this.gameId === "loto-pamir-legends" &&
        this.scoreboardMode === "fruit" &&
        this.isBonusGame
      ) {
        AudioUtils.stopSound("main-theme-pamir-legends");
        AudioUtils.stopSound("bonus-theme-pamir-legends");
        AudioUtils.playSound("bonus-theme-pamir-legends", 0, 0, true);
      } else if (
        this.isSoundOn &&
        this.gameId === "loto-pearl" &&
        this.scoreboardMode === "fruit" &&
        !this.isBonusGame
      ) {
        AudioUtils.stopSound("main-theme-pearl");
        AudioUtils.playSound("main-theme-pearl", 0, 0, true);
      } else if (
        this.isSoundOn &&
        this.gameId === "loto-pearl" &&
        this.scoreboardMode === "fruit" &&
        this.isBonusGame
      ) {
        AudioUtils.stopSound("main-theme-pearl");
        AudioUtils.stopSound("bonus-theme-pearl");
        AudioUtils.playSound("bonus-theme-pearl", 0, 0, true);
      } else if (
        this.isSoundOn &&
        this.gameId === "loto-misr" &&
        this.scoreboardMode === "fruit" &&
        !this.isBonusGame
      ) {
        AudioUtils.stopSound("main-theme-misr");
        AudioUtils.playSound("main-theme-misr", 0, 0, true);
      } else if (
        this.isSoundOn &&
        this.gameId === "loto-misr" &&
        this.scoreboardMode === "fruit" &&
        this.isBonusGame
      ) {
        AudioUtils.stopSound("main-theme-misr");
        AudioUtils.stopSound("bonus-theme-misr");
        AudioUtils.playSound("bonus-theme-misr", 0, 0, true);
      } else if (
        this.isSoundOn &&
        this.gameId === "loto-khoja" &&
        this.scoreboardMode === "fruit" &&
        !this.isBonusGame
      ) {
        AudioUtils.stopSound("main-theme-khoja");
        AudioUtils.playSound("main-theme-khoja", 0, 0, true);
      } else if (
        this.isSoundOn &&
        this.gameId === "loto-khoja" &&
        this.scoreboardMode === "fruit" &&
        this.isBonusGame
      ) {
        AudioUtils.stopSound("main-theme-khoja");
        AudioUtils.stopSound("bonus-theme-khoja");
        AudioUtils.playSound("bonus-theme-khoja", 0, 0, true);
      }
    },
  },
});
