
import StartScreen from "./components/StartScreen.vue";
import { defineComponent } from "vue";
import DESIGN_TYPE from "./main";
import store from "./store";
import ModalList from "@/components/Modals/ModalLIst.vue";
import { AudioUtils, debounce } from "./utils";
import { GameId } from "@/types/user";
import { api } from "./api";

export default defineComponent({
  components: {
    ModalList,
    StartScreen,
  },
  data() {
    return {
      debouncedResizeHandler: undefined as Function | undefined,
      ro: undefined as ResizeObserver | undefined,
    };
  },
  computed: {
    designType(): string {
      return `--${DESIGN_TYPE}`;
    },
    gameCss(): object {
      return {
        "--mevaho": this.gameId === "loto-mevaho-5",
        "--gambusaki": this.gameId === "loto-gambusaki-9",
        "--gambusaki-gold": this.gameId === "loto-gambusaki-gold-9",
        "--khoja": this.gameId === "loto-khoja",
        "--kissaho": this.gameId === "loto-kissaho",
        "--pearl": this.gameId === "loto-pearl",
        "--silk-way": this.gameId === "loto-silk-way",
        "--misr": this.gameId === "loto-misr",
        "--pamir-legends": this.gameId === "loto-pamir-legends",
        "--fullscreen": this.isWidescreen && this.isDesktop,
      };
    },
    isAppLoaded(): boolean {
      return store.state.ui.isAppLoaded;
    },
    gameId(): GameId {
      return store.state.currentGameId;
    },
    isWidescreen(): boolean {
      return store.state.userData?.settings?.widescreen === "on";
    },
    isDesktop(): boolean {
      return store.state.uiVersion === "desktop";
    },
    isFruitMode(): boolean {
      return store.state.userData.settings.mevaho_mode === "fruit";
    },
    isBonusGame(): boolean {
      return store.state.bonus.isOn;
    },
  },
  methods: {
    resizeHandler() {
      store.commit("forceResize");
      // update mobile/desktop version after size changes
      store.dispatch("updateUIVersion");
    },
    onchange(e: any) {
      const userAgent = navigator.userAgent || navigator.vendor;
      if (/Macintosh|iPad|iPhone|iPod/.test(userAgent)) {
        console.log("connection offline for ios: ", e);
        api.disconnectFixIos();
      }
    },
    playSound() {
      AudioUtils.resumeAudioContext();
      if (this.isFruitMode && !this.isBonusGame) {
        if (this.gameId === "loto-pamir-legends") {
          AudioUtils.stopSound("main-theme-pamir-legends");
          AudioUtils.stopSound("bonus-theme-pamir-legends");
          AudioUtils.playSound("main-theme-pamir-legends", 0, 0, true);
        } else if (this.gameId === "loto-pearl") {
          AudioUtils.stopSound("main-theme-pearl");
          AudioUtils.stopSound("bonus-theme-pearl");
          AudioUtils.playSound("main-theme-pearl", 0, 0, true);
        } else if (this.gameId === "loto-misr") {
          AudioUtils.stopSound("main-theme-misr");
          AudioUtils.stopSound("bonus-theme-misr");
          AudioUtils.playSound("main-theme-misr", 0, 0, true);
        } else if (this.gameId === "loto-khoja") {
          AudioUtils.stopSound("main-theme-khoja");
          AudioUtils.stopSound("bonus-theme-khoja");
          AudioUtils.playSound("main-theme-khoja", 0, 0, true);
        }
        console.log("playsound created");
      } else if (this.isFruitMode && this.isBonusGame) {
        if (this.gameId === "loto-pamir-legends") {
          AudioUtils.stopSound("main-theme-pamir-legends");
          AudioUtils.stopSound("bonus-theme-pamir-legends");
          AudioUtils.playSound("bonus-theme-pamir-legends", 0, 0, true);
        } else if (this.gameId === "loto-pearl") {
          AudioUtils.stopSound("main-theme-pearl");
          AudioUtils.stopSound("bonus-theme-pearl");
          AudioUtils.playSound("bonus-theme-pearl", 0, 0, true);
        } else if (this.gameId === "loto-misr") {
          AudioUtils.stopSound("main-theme-misr");
          AudioUtils.stopSound("bonus-theme-misr");
          AudioUtils.playSound("bonus-theme-misr", 0, 0, true);
        } else if (this.gameId === "loto-khoja") {
          AudioUtils.stopSound("main-theme-khoja");
          AudioUtils.stopSound("bonus-theme-khoja");
          AudioUtils.playSound("bonus-theme-khoja", 0, 0, true);
        }
      }
    },
    autoClick() {
      if (
        this.gameId === "loto-pamir-legends" ||
        this.gameId === "loto-pearl" ||
        this.gameId === "loto-misr" ||
        this.gameId === "loto-khoja"
      ) {
        setTimeout(() => {
          if (this.$refs.autoClickButton) {
            if (this.isDesktop) {
              const clickEvent = new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
              });
              (this.$refs.autoClickButton as HTMLButtonElement).dispatchEvent(clickEvent);
              console.log("btn click mounted");
            } else if (!this.isDesktop) {
              const touchEvent = new TouchEvent("touchstart", {
                bubbles: true,
                cancelable: true,
                view: window,
              });
              (this.$refs.autoClickButton as HTMLButtonElement).dispatchEvent(touchEvent);
              console.log("btn touchstart");
            }
          }
        }, 500);
      }
    },
  },
  created() {
    this.debouncedResizeHandler = debounce(this.resizeHandler, 250);
    this.ro = new ResizeObserver(() => this.debouncedResizeHandler!());
    this.ro.observe(document.body);
    if (!this.ro) {
      window.addEventListener("resize", this.debouncedResizeHandler as any);
    }
    store.dispatch("updateUIVersion");
    window.addEventListener("offline", this.onchange);
  },
  beforeUnmount() {
    window.removeEventListener("offline", this.onchange);
  },
  watch: {
    isAppLoaded: function (n) {
      if (n === true) {
        this.autoClick();
      }
    },
  },
});
